@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lusitana&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

body {
  max-width: 100%;
  overflow-x: hidden;
}

h2 {
  font-family: "Open Sans", sans-serif;
  line-height: 1.375;
}

h1 {
  font-family: "Raleway", sans-serif;
  line-height: 1.375;
}

img {
  border-radius: 0.25rem;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.cursive {
  font-weight: 300;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 150px;
}

#bmc-wbtn {
  bottom: 15px;
}

#bmc-wbtn + div {
  bottom: 15px;
}

.custom-react-multi-carousel-dot-list {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.custom-react-multi-carousel-dot {
  position: relative;
}

.custom-react-multi-carousel-dot button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background 0.5s;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
}
.custom-react-multi-carousel-dot button:hover:active {
  background: #ffffff;
}
.custom-react-multi-carousel-dot--active button {
  background: #ffffff;
}

.link-disable {
  pointer-events: none;
}

.goalsInverse {
  direction: rtl;
}

.over {
  z-index: 99;
}

.loader-container {
  position: absolute;
  background-color: rgb(52, 63, 79);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 999;
}

.loader {
  position: absolute;
  top: calc(55% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 5px solid rgb(255, 255, 255);
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
  z-index: 999;
}

.loader--hide {
  display: none;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
